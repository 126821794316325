import _defineProperty from "/ecomm-brands/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

var _this = this;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import ApolloClient from 'apollo-client';
import fetchPonyfill from 'fetch-ponyfill';
import { createPersistedQueryLink } from 'apollo-link-persisted-queries';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import _ from 'lodash';
import PublicEnv from 'shared/utils/public-env';
import possibleTypes from 'shared/schemas/fragment-types';
import { VersionLink } from './version-link';

var _fetchPonyfill = fetchPonyfill({}),
    fetch = _fetchPonyfill.fetch;

export var EXTENSION_ERRORS = {
  AUTHERROR: ['AUTHENTICATION_ERROR', 'UNAUTHENTICATED'],
  FORBIDDEN: ['FORBIDDEN'],
  SESSIONEXPIRED: ['SESSION_INVALID']
};

var errorLinkFactory = function errorLinkFactory() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      onSessionExpired = _ref.onSessionExpired,
      onAuthError = _ref.onAuthError;

  return onError(function (_ref2) {
    var graphQLErrors = _ref2.graphQLErrors,
        networkError = _ref2.networkError;

    if (graphQLErrors) {
      graphQLErrors.map(function (error) {
        var _error$extensions, _error$extensions2, _error$extensions3;

        if (EXTENSION_ERRORS.FORBIDDEN.includes((_error$extensions = error.extensions) === null || _error$extensions === void 0 ? void 0 : _error$extensions.code)) {
          // console.error('Forbidden');
          onAuthError === null || onAuthError === void 0 ? void 0 : onAuthError.call(_this, error);
        }

        if (EXTENSION_ERRORS.AUTHERROR.includes((_error$extensions2 = error.extensions) === null || _error$extensions2 === void 0 ? void 0 : _error$extensions2.code)) {
          // console.error('Auth error');
          onSessionExpired === null || onSessionExpired === void 0 ? void 0 : onSessionExpired.call(_this, error);
        }

        if (EXTENSION_ERRORS.SESSIONEXPIRED.includes((_error$extensions3 = error.extensions) === null || _error$extensions3 === void 0 ? void 0 : _error$extensions3.code)) {
          // console.error('Session expired');
          onSessionExpired === null || onSessionExpired === void 0 ? void 0 : onSessionExpired.call(_this, error);
        }
      });
    }

    if (networkError) {
      console.error("Network Error: ".concat(networkError.message));
    }
  });
};

var setAuthorization = function setAuthorization(operation, forward) {
  var headers = {};

  if (typeof window !== 'undefined') {
    var token = window.localStorage.getItem('access-token');
    headers.url = window.location.href;

    if (token) {
      headers.authorization = token;
    }
  }

  operation.setContext(function () {
    return {
      headers: headers
    };
  });
  return forward(operation);
};

var appMapping = {
  admin: 'Admin',
  brands: 'Brands'
};
var fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: possibleTypes
}); // Fix for issues with LogRocket and Apollo Client

var fetcher = {}; // eslint-disable-next-line lodash/prefer-lodash-typecheck

if (typeof window !== 'undefined' && typeof window.fetch === 'function') {
  fetcher.fetch = function () {
    var _window;

    return (_window = window).fetch.apply(_window, arguments);
  }; // eslint-disable-next-line lodash/prefer-lodash-typecheck

} else if (typeof fetch !== 'undefined') {
  fetcher.fetch = fetch;
}

function setupMainApolloClient(appKey) {
  var _ref3 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      onSessionExpired = _ref3.onSessionExpired,
      onAuthError = _ref3.onAuthError,
      _ref3$onUpdateVersion = _ref3.onUpdateVersion,
      onUpdateVersion = _ref3$onUpdateVersion === void 0 ? _.noop : _ref3$onUpdateVersion;

  var appName = _.get(appMapping, appKey, '(NO APP SET)');

  var versionLinkInstance = VersionLink(onUpdateVersion);
  var errorLink = errorLinkFactory({
    onSessionExpired: onSessionExpired,
    onAuthError: onAuthError
  });
  var httpLink = new HttpLink(_objectSpread({
    credentials: 'include',
    uri: PublicEnv.graphqlUri,
    useGETForHashedQueries: true
  }, fetcher));
  return new ApolloClient({
    name: "Dutchie ".concat(appName, " Express GraphQL (").concat(PublicEnv.appEnv, ")"),
    version: PublicEnv.currentVersion,
    cache: new InMemoryCache({
      fragmentMatcher: fragmentMatcher
    }),
    link: ApolloLink.from([versionLinkInstance, setAuthorization, errorLink, createPersistedQueryLink({
      useGETForHashedQueries: true
    }), httpLink])
  });
}

export default {
  setupMainApolloClient: setupMainApolloClient
};